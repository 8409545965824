@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

/* General container for the bridge interface */
.bridge-centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  font-family: "VT323", monospace;
  color: white;
  padding: 20px;
}

/* Container for the dropdown menus and balances */
.bridge-menu {
  position: relative; /* Needed for absolute positioning of child elements */
  width: 100%; /* Define a width */
  height: 200px; /* Example height */

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Individual network card (dropdown + balance display) */
.network-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

/* Network box (contains dropdown, balance, etc.) */
.network-box {
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center;
  width: 350px;
  height: 200px;
  gap: 15px; /* Add spacing between child elements */
}

/* Styling for dropdown menus */
.bridge-dropdown {
  width: 80%;
  padding: 10px;
  font-size: 3rem;
  border: 2px solid hsl(46, 100%, 50%);
  border-radius: 10px;
  background-color: #1c1c1c;
  color: hsl(46, 100%, 50%);
  text-align: center;
  outline: none;
  cursor: pointer;
  font-family: "VT323", monospace;
  appearance: none; /* Keeps dropdown arrow intact */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='hsl(46, 100%, 50%)'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}
/* Styling for the options inside the dropdown menu */
.bridge-dropdown option {
  font-size: 2rem; /* Set the font size for options */
  padding: 10px; /* Adjust padding for a smaller height of options */
}

.bridge-dropdown:hover {
  background-color: hsl(46, 100%, 50%);
  color: black;
  width: 80%;
}

.bridge-dropdown:disabled {
  background-color: #333;
  color: #777;
  cursor: not-allowed;
}

/* Styling for the balances below the dropdowns */
.network-balance {
  margin-top: 10px;
  color: hsl(46, 100%, 50%);
  font-size: 1.2rem;
}

/* Arrow between the dropdown menus */
.arrow {
  font-size: 3rem;
  color: hsl(46, 100%, 50%);
  margin: 0 20px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}

/* Input container for entering bridge amount */
.bridge-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it spans full width for easy centering */
  margin-top: 20px; /* Add some spacing */
  /* border: 1px solid red; /* temporary for debugging */
}

.input-card {
  background-color: #1c1c1c;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  color: white;
  /* border: 1px solid blue; /* temporary for debugging */
}

/* Styling for the input box */
.bridge-input {
  width: 100%;
  padding: 15px;
  padding-right: 50px;
  font-size: 1.2rem;
  background-color: transparent;
  color: hsl(46, 100%, 50%);
  border: none;
  outline: none;
  text-align: left;
  font-family: "VT323", monospace;
}

/* Placeholder text styling */
.bridge-input::placeholder {
  color: gray;
  font-size: 0.9rem;
}

/* Remove input arrows for number fields (all browsers) */
.bridge-input::-webkit-outer-spin-button,
.bridge-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bridge-input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* Styling for the Max button inside the input */
.max-button {
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #1c1c1c;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border: 2px solid hsl(46, 100%, 50%);
  color: hsl(46, 100%, 50%);
  font-family: "VT323", monospace;
}

.max-button:hover {
  background-color: hsl(46, 100%, 50%);
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Bridge button styling */
.bridge-button {
  margin-top: 20px;
  padding: 12px 25px;
  font-size: 1.5rem;
  border-radius: 10px;
  border: none;
  background-color: hsl(46, 100%, 50%);
  color: black;
  cursor: pointer;
  font-family: "VT323", monospace;
  transition: all 0.3s ease;
}

.bridge-button:hover {
  background-color: #1c1c1c;
  color: hsl(46, 100%, 50%);
  border: 2px solid hsl(46, 100%, 50%);
}

/* Utility class for centering content */
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Success or error messages styling */
.message {
  margin-top: 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #444;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid hsl(46, 100%, 50%);
}

.message.success {
  color: hsl(120, 70%, 50%);
}

.message.error {
  color: hsl(0, 70%, 50%);
}

/* For Firefox */
.bridge-input[type="number"] {
  -moz-appearance: textfield;
}

/* Container for the moving images */
.image-container {
  position: absolute;
  top: 30%; /* Aligned with balance cards */
  left: 37%; /* Narrow to appear between the balance cards */
  width: 25%; /* Constrain between cards */
  height: 18%; /* Match height of balance area */
  z-index: -1;
  pointer-events: none;
}

.moving-image {
  position: absolute;
  animation: moveAcross 6s linear infinite, rotate 10s linear infinite;
}

@keyframes moveAcross {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Container for the moving images */
.image-container {
  position: absolute;
  top: 30%; /* Adjust as needed */
  left: 37%; /* Narrow to appear between the balance cards */
  width: 25%; /* Constrain between cards */
  height: 15%; /* Match height of balance area */
  z-index: -1;
  pointer-events: none;
}

.bridge-centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.bridge-balances {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.balance-card {
  width: 40%;
  padding: 20px;
  background-color: #2f2f2f;
  color: white;
  border-radius: 10px;
}

/* Container for Moving Images */
.image-container {
  position: absolute; /* Maintain positioning if necessary */
  top: 0;
  left: 10%;
  width: 80%; /* Match the width of the parent container */
  height: 100%; /* Match the height of the parent container */
  overflow: hidden; /* Prevent images from spilling out */
  z-index: -1; /* Ensure it's behind the main content */
  pointer-events: none; /* Make it non-interactive */
}

.moving-image {
  position: absolute;
  animation: moveAcross linear infinite, rotate 10s linear infinite;
}

/* Keyframes for the image animations */
@keyframes moveAcross {
  0% {
    left: -10%; /* Start from the left side of the screen */
  }
  100% {
    left: 110%; /* Move to the right side */
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light border */
  border-top: 4px solid hsl(46, 100%, 50%); /* Solid border on top */
  border-radius: 50%;
  width: 25px; /* Adjust size as needed */
  height: 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Success message styles */
.success-message {
  margin-top: 20px;
  color: green;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
}

/* Error message styles */
.error-message {
  margin-top: 20px;
  color: red;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
}
