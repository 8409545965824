/* Styling for current active link */
.active {
  color: orange !important;
}

.bridge-centered-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-top: 25%;
  text-align: center;
  position: relative; /* To support absolute positioning for the images */
}

/* New styles for the left and right images */
.left-image {
  position: absolute;
  left: 2%; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 150px; /* Adjust size as needed */
  height: auto;
}

.right-image {
  position: absolute;
  right: 2%; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 150px; /* Adjust size as needed */
  height: auto;
}

.bridge-balances {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;
}

.balance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bridge-input-container {
  display: flex;
  position: relative;
  align-items: center;
  margin: 10px 0;
  width: 250px; /* Set a fixed width for the input container */
}

.bridge-input {
  padding: 10px;
  padding-right: 50px; /* Ensure space for the max button */
  width: 100%; /* Input takes the full width of its container */
  font-size: 1rem;
  text-align: right;
  border-radius: 12px;
  border: 2px solid #ccc;
  background-color: transparent;
  color: white;
}

.bridge-max-button {
  position: absolute;
  right: 10px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 2px solid #ccc;
  background-color: transparent;
  font-size: 0.9rem;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
}

.bridge-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid #ccc;
  background-color: transparent;
  color: white;
  margin-top: 15px;
  max-width: 200px; /* Optional: limit the maximum width if needed */
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: var(--z-fixed);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2rem;
  box-sizing: border-box;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: 2.5vw;
  font-family: var(--second-font);
  position: absolute;
  left: 2rem;
}

.nav__menu {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 1rem;
}

.nav__list {
  display: flex;
  justify-content: flex-end;
  gap: 2.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  justify-content: flex-end;
}

.connect-button-classname {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

@media screen and (max-width: 959px) {
  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }

  .show-menu {
    right: 0;
  }

  .nav__list {
    flex-direction: column;
    row-gap: 2.5rem;
  }

  .nav__right {
    position: absolute;
    right: 2rem;
    top: 10px;
  }
}

@media screen and (min-width: 959px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}
