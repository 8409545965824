@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --header-height: 3.5rem;
  --first-color: hsl(46, 100%, 50%);
  --title-color: hsl(0, 0%, 95%);
  --text-color: hsl(0, 0%, 75%);
  --body-color: hsl(0, 0%, 6%);
  --body-font: "Montserrat", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
