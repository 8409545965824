.container-div {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-top: 30%; /* Adjust to move text closer to the top */
  text-align: center;
}

.p1 {
  margin-top: 25vh;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding: 10px;
  font-size: 300%;
}

.p2 {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding: 10px;
  font-size: 100%;
}

.data-card {
  background-color: #1c1c1c;
  padding: 40px; /* Adjust padding to make the content more compact or spacious */
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  width: 100%; /* Change width to make the boxes larger or smaller */
  color: hsl(46, 100%, 50%);
  transition: transform 0.3s ease;
  text-align: center;
}

.data-card h3 {
  font-size: 2rem;
  color: hsl(46, 100%, 50%);
  text-align: center;
}

.data-card p {
  font-size: 3rem;
  color: hsl(46, 100%, 50%);
  text-align: center;
}

.data-card:hover {
  transform: translateY(-10px);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  justify-items: center;
  align-items: center;
  text-align: center;
}
